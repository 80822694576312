<template>
    <section class="sobre-home mt-5">
    <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="216" viewBox="0 0 1366 216" fill="none" preserveAspectRatio="none">
        <path d="M1366.95 0.425781H-0.179932V15.616C443.845 276.188 887.87 287.873 1366.95 15.616V0.425781Z" fill="white"/>
      </svg>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6" v-for="(sobre, index) in sobreConteudos" :key="index">
                <img :src="sobre.imagem_sobre" alt="Sobre Home" class="person-sobre">
            </div>

            <div class="col-lg-6">
                <div class="info-sb-home">
                    <div class="titulo-sobre" v-for="(sobre, index) in sobreConteudos" :key="index">
                        <h3>
                            {{ sobre.titulo_sobre }}
                        </h3>
                    </div>

                    <ul class="lista-principios mt-5">
                        <li v-for="(principio, index) in principios" :key="index">
                            <span class="titulo-principio">
                                {{ principio.titulo_principio }}
                            </span> <br>
                            <span class="subtitulo-principios">
                                {{ principio.subtitulo_principio }}
                            </span>
                        </li>
                    </ul>

                    <div class="btn-sobre">
                        <router-link to="/sobre" aria-label="sobre">
                            Sobre o cref 20
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';
export default {
    name: 'SobreHomeVue',
    data() {
        return {
            sobreConteudos: [],
            principios: []
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.sobreConteudos = response.data[0].corpo.sobre_home.map((sobre) => ({
                imagem_sobre: sobre.imagem_sobre_home,
                titulo_sobre: sobre.titulo_sobre_home
            }));

            this.principios = response.data[0].corpo.principios_home.map((principio) => ({
                titulo_principio: principio.titulo_principios,
                subtitulo_principio: principio.subtitulo_principios
            }))
        });
    }
}
</script>

<style scoped>
.col-lg-6 img {
    border-bottom-left-radius: 779px 435px;
    border-bottom-right-radius: 779px 435px;
    height: 584px;
    object-fit: cover;
}
.sobre-home {
    background-color: #12575F;
}
.sobre-home svg {
    width: 100%;
    margin-top: -2px;
}
.sobre-home .col-lg-6 {
    margin-top: 6rem;
}
.sobre-home .person-sobre {
    width: 100%;
}
.sobre-home .info-sb-home .titulo-sobre h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.sobre-home .info-sb-home .lista-principios {
    list-style-image: url(../../assets/imgs/icon-principios.png);
}
.sobre-home .info-sb-home .lista-principios li {
    margin: 15px 0;
}
.sobre-home .info-sb-home .lista-principios li .titulo-principio {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.sobre-home .info-sb-home .lista-principios li .subtitulo-principios {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.sobre-home .info-sb-home .btn-sobre {
    margin-top: 40px;
}
 .sobre-home .info-sb-home .btn-sobre a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}
@media (max-width: 820px) {
    .sobre-home {
        position: relative;
    }
}
@media (max-width: 765px) {
    svg {
        display: none;
	}
}
 
</style>