<template>

    <section class="noticia-interna">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" v-for="(post, index) in postLeitura" :key="index">
                    <ul class="element-inicial" role="list" v-for="(campo, indexCampo) in post.campos" :key="indexCampo">
                        <li role="listitem" class="categoria">
                            {{ post.categoria }}
                        </li>
                        <li role="listitem" class="data-publicacao" v-if="campo.data != ''">
                            Publicado em: {{ campo.data }}
                        </li>
                    </ul>
                </div>
                
                <div class="col-lg-12">
                    <div class="titulo-materia" v-for="(noticiaGeral, index) in postLeitura" :key="index">
                        <h2>
                            {{ noticiaGeral.titulo }}
                        </h2>
                    </div>
                </div>

                <div class="col-lg-12 my-4" v-for="(post, index) in postLeitura" :key="index">
                    <div class="thumb-capa">
                        <img :src="post.capa" alt="Capa">
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-1">
                            <ul class="redes-sociais" role="list">
                                <li role="listitem">
                                    <a :href="'https://www.facebook.com/sharer/sharer.php?&amp;u='+ this.urlFinal" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                            <path d="M14.7325 7.52668H11.6839V5.79698C11.6839 4.90445 11.7565 4.34229 13.0356 4.34229H14.6512V1.59206C13.865 1.51076 13.0745 1.47098 12.2832 1.47271C9.93685 1.47271 8.22444 2.90577 8.22444 5.53666V7.52668H5.62988V10.9861L8.22444 10.9852V18.7698H11.6839V10.9835L14.3355 10.9826L14.7325 7.52668Z" fill="#45A047"/>
                                        </svg>
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'https://twitter.com/home?' + this.urlFinal" target="_blank">
                                        <i class="bi bi-twitter"></i>
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'https://wa.me/?text=' + this.urlFinal" target="_blank">
                                        <i class="bi bi-whatsapp"></i>
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'mailto:?' + this.urlFinal" target="_blank">
                                        <i class="bi bi-envelope"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9">
                            <div class="titulo"  v-for="(noticiaGeral, index) in postLeitura" :key="index">
                                <h3>
                                    {{ noticiaGeral.titulo }}
                                </h3>
                            </div>
                            <div class="texto" v-for="(post, index) in postLeitura" :key="index">
                                <p class="materia" v-html="post.conteudo">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="box-compartilhar my-4">
                        <ul class="list-redes" role="list">
                            <li role="listitem" class="titulo-list">
                                Compartilhar esse post: 
                            </li>
                            <li role="listitem">
                                <a :href="'https://www.facebook.com/sharer/sharer.php?&amp;u='+ this.urlFinal" target="_blank">
                                    <img :src="require('@/assets/imgs/icon-facebook.png')" alt="FB Icon">
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'https://twitter.com/home?' + this.urlFinal" target="_blank">
                                    <i class="bi bi-twitter"></i>
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'https://wa.me/?text=' + this.urlFinal" target="_blank">
                                    <i class="bi bi-whatsapp"></i>
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'mailto:?' + this.urlFinal" target="_blank">
                                    <i class="bi bi-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <NoticiasRelacionadas/>
   
    
    <FooterInternas/>
</template>

<script>
import axios from 'axios';
import NoticiasRelacionadas from '@/components/NoticiasRelacionadas/NoticiasRelacionadas.vue'
import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';


export default {
    name: 'NoticiaLeitura',
    components: {
        FooterInternas,
        NoticiasRelacionadas
    },
    data() {
        return {
            postsListagem: [],
            postLeitura: [],
            urlFinal: ''
        }
    },
    watch: {
        $route() {
            this.fetchData();
            this.scrollTop();
        },
    },
    methods: {
        fetchData() {
            const NOTICIA = 'https://api.cref20.org.br/wp-json/cref/v1/post/' + this.$route.params.noticia;
            // console.log(NOTICIA)

            axios.get(NOTICIA).then((response) => {
                this.postLeitura = response.data.map((dados) => ({
                    titulo: dados.titulo,
                    conteudo: dados.texto_conteudo,
                    capa: dados.capa_topo,
                    categoria: dados.categoria,
                    campos: dados.acf_campos.map((conteudo) => ({
                        data: conteudo.data_do_post,
                    })),
                }));
            });
        },

        scrollTop() {
            window.scrollTo(0,0);
        }
    },
    created() {
        this.fetchData();

        this.urlFinal = window.location.href;

        this.scrollTop();
    }
}
</script>


<style scoped>
.noticia-interna .element-inicial {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}
.noticia-interna .element-inicial .categoria {
    background-color: #12575F;
    padding: 15px 10px;
    border-radius: 10px;
    width: 111px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.noticia-interna .element-inicial .data-publicacao {
    color: #5E5E5E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-left: 10px;
}
.noticia-interna .titulo-materia h2 {
    color: #393939;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
}
.noticia-interna .thumb-capa img {
    width: 100%;
}
.noticia-interna .titulo h3 {
    color: #6A6B74;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}
.noticia-interna .texto p {
    color: #89898B;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
}
.noticia-interna .redes-sociais {
    padding: 0;
    list-style: none;
}
.noticia-interna .redes-sociais li {
    margin: 12px 0;
}
.noticia-interna .redes-sociais li a,
.box-compartilhar .list-redes a {
    height: 70px;
    width: 70px;
    border: 1px solid #D1D1D1;
    padding: 20px;
    border-radius: 50px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}
.noticia-interna .redes-sociais li a i {
    color: #45A047;
}

.noticia-interna .box-compartilhar {
    border-radius: 10px;
    background: #F1F1F1;
    padding: 20px;
}
.box-compartilhar .list-redes {
    display: flex;
    list-style: none;
    align-items: center;
    margin-top: 10px;
    padding: 0;
}
.box-compartilhar .list-redes li .titulo-list {
    color: #747474 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;    
}
.box-compartilhar .list-redes li {
    margin: 0 10px;
}
.box-compartilhar .list-redes a {
    height: 50px;
    width: 50px;
}
.box-compartilhar .list-redes li a i {
    color: #45A047;
}
</style>