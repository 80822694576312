<template>
     <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none" preserveAspectRatio="none">
    <path d="M-1.63184 215.66L1412.09 215.66L1412.09 200.469C952.93 -60.1029 493.774 -71.7878 -1.63184 200.47L-1.63184 215.66Z" fill="#EEEEEE" fill-opacity="0.8"/>
    </svg>
    <section class="noticia-relacionadas">
        <div class="container">
            <div class="row">
                <div class="titulos">
                    <h2>
                        Notícias Relacionadas
                    </h2>
                    <div class="div">
                        <router-link class="btn-mais" to="/noticias">
                            Carregar mais
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4" v-for="(noticia, index) in postsListagem.slice(0, 3)" :key="index">
                    <div class="box-noticias">
                        <div class="thumb-noticias" :style="{backgroundImage: `url(${noticia.capa})`}">
                            <span class="categoria">
                                {{ noticia.categoria }}
                            </span>
                        </div>
                        <div class="info-noticias my-3">
                            <div class="data">
                                <span>
                                    {{ noticia.data_publicado }}
                                </span>
                            </div>
                            <div class="titulo-materia">
                                <h4 v-html="noticia.titulo"></h4>
                            </div>
                            <div class="link">
                                <router-link :to="{name: 'Leitura Notícia', params: {noticia: noticia.slug}}" aria-label="Ler mais">
                                    Ler mais
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

const NOTICIAS = 'https://api.cref20.org.br/wp-json/cref/v1/posts';

export default {
    name: 'NoticiasRelacionadas',
    data() {
        return {
            postsListagem: [],
            postLeitura: [],
            urlFinal: ''
        }
    },
    mounted() {
        axios.get(NOTICIAS).then((response) => {
            this.postsListagem = response.data.map((noticia) => ({
                slug: noticia.slug_post,
                titulo: noticia.titulo_post,
                categoria: noticia.categoria_post,
                capa: noticia.capa_destaque,
                data_publicado: noticia.data_publicacao
            }))
        });
    }
}
</script>

<style scoped>
svg {
    width: 100%;
}

.noticia-relacionadas {
    background-color: #EEEEEECC;
}
.noticia-relacionadas .thumb-noticias {
    height: 369px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.noticia-relacionadas .thumb-noticias span {
    padding: 10px;
    background-color: #12575F;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    top: 17px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.noticia-relacionadas .titulos {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.noticia-relacionadas .titulos h2 {
    color: #353535;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}
.box-noticias .link a{
    color: #313131;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 3px solid #12575F;
    text-decoration: none;
}
.noticia-relacionadas .titulos .btn-mais {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #45A047;
    padding: 20px;
    border-radius: 50px;
    text-decoration: none;
}
</style>